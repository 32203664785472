import React, { Component } from "react";

import { HashLink as HLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { addClass: "" };
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll.bind(this));
	}
	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll.bind(this));
	}
	handleScroll() {
		let lastScrollY = window.scrollY;

		if (lastScrollY >= 70) {
			this.setState((state, props) => ({
				addClass: "affix-top",
			}));
		} else {
			this.setState((state, props) => ({
				addClass: "affix",
			}));
		}
	}
	render() {
		let logoAlt = "Rappix";

		return (
			<div>
				<nav
					id='navbar'
					className={`${this.state.addClass} navbar navbar-custom navbar-fixed-top`}
					data-spy='affix'
					data-offset-top='70'
				>
					<div className='container'>
						<div className='row'>
							<div className='navbar-header page-scroll'>
								<button
									type='button'
									className='navbar-toggle'
									data-toggle='collapse'
									data-target='#bs-example-navbar-collapse-1'
								>
									<i className='fa fa-bars'></i>
								</button>
								<a
									className='navbar-brand page-scroll logo-light'
									href={`${process.env.PUBLIC_URL}/`}
								>
									<img
										alt={logoAlt}
										src={
											process.env.PUBLIC_URL +
											"/image/logo.png"
										}
									/>
								</a>
								<a
									className='navbar-brand page-scroll logo-clr'
									href={`${process.env.PUBLIC_URL}/`}
								>
									<img
										alt={logoAlt}
										src={
											process.env.PUBLIC_URL +
											"/image/logo-clr.png"
										}
									/>
								</a>
							</div>
							<div
								className='collapse navbar-collapse'
								id='bs-example-navbar-collapse-1'
							>
								<div className='right-nav text-right'>
									<ul className='nav navbar-nav menu'>
										<li>
											<HLink
												className='nav-link text-black'
												smooth
												to={`/`}
											>
												Home
											</HLink>
											{/* <ul className="sub-menu">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/home-v2">Home V2</Link></li>
                                                <li><Link to="/home-v3">Home V3</Link></li>
                                                <li><Link to="/home-v4">Home V4</Link></li>
                                            </ul> */}
										</li>
										{/* <li>
											<HLink
												className='nav-link text-black'
												smooth
												to={`/feature`}
											>
												Feature
											</HLink>
										</li> */}
										<li>
											<HLink
												className='nav-link text-black'
												smooth
												to={`/download`}
											>
												Download
											</HLink>
										</li>
										<li>
											<HLink
												className='nav-link text-black'
												smooth
												to={`/blog-single`}
											>
												I am a business owner
											</HLink>
										</li>
										{/* <li>
                                            <HLink className="nav-link" smooth to={`/#pricing`}>Pricing</HLink>
                                        </li> */}
										{/* <li>
											<HLink
												className='nav-link'
												smooth
												to={`/#blog`}
											>
												Blog
											</HLink>
											<ul className='sub-menu'>
												<li>
													<Link to='/blog'>Blog</Link>
												</li>
												<li>
													<Link to='/blog-single'>
														Blog Single
													</Link>
												</li>
											</ul>
										</li> */}
									</ul>
									{/* <div className="nav-btn">
                                        <a href={'#'} className="btn btn-border">Try It for 7 Days</a>
                                    </div> */}
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}

export default Navbar;
