import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Faq extends Component {
    render () {

        let getData = homedata.faq
        let publicUrl = process.env.PUBLIC_URL+'/'


        return  <section className="sec-pad-lg pt-0 grad-yellow bg-memphis">
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="col-md-8 col-md-offset-2">
						<div className="faq-wrapper clearfix">
							<div className="res-margin wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
								<div className="section-text text-center">
								<h4>{getData.title}</h4>
								</div>
							</div>
							<div className="col-md-7 col-sm-7 faq wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
								<div className="panel-group res-margin" id="accordion" role="tablist" aria-multiselectable="true">
									{
										getData.accordionContent.map( ( element, i ) => {
							
											let collapseIn = '';

											if( i === 0 ) {
												collapseIn = ' in';
											}
							
											return(
											<div key={i} className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingOne">
													<h4 className="panel-title">
														<a role="button" data-toggle="collapse" data-parent="#accordion" href={'#collapse'+i} aria-expanded="true" aria-controls="collapseOne">
															<i className="more-less glyphicon glyphicon-minus"></i>
															{element.title}
														</a>
													</h4>
												</div>
												<div id={'collapse'+i} className={`panel-collapse collapse${collapseIn}`} role="tabpanel" aria-labelledby="headingOne">
													<div className="panel-body">
														<p>{element.content}</p>
													</div>
												</div>
											</div>
											)
										})
									}

								</div>
							</div>
							<div className="col-md-4 col-md-offset-1 col-sm-5">
								<img src={publicUrl+getData.image}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Faq
