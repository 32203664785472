import React, { Component } from 'react';
import Footer from '../global-components/footer';
import Comments from './comments'
import PageHeader from '../global-components/page-header'
import Sidebar from '../blog-components/sidebar';
import singleBlogdata from '../../data/single-blogdata'

class Privacy extends Component {
    render() {

        // let getdata = singleBlogdata.singlepostdata;
        let publicUrl = process.env.PUBLIC_URL + '/'

        return (
            <div>
                <div id="home" className="hero-single grdnt-yellow style-wave-2 bg-trans-2">
                    <div className="container">
                        <div className="row text-center">
                            <div className="intro-text light">
                                <h3>Privacy Policy</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="blog" className="sec-pad blog">
                    <div className="container">
                        <div className="row">
                            <div className="post row">
                                <div className="col-md-12 col-sm-12 res-margin">
                                    <div className="post-col">
                                        {/* <img src={publicUrl+getdata.image}/> */}
                                        <div className="post-content">
                                            <div className="spce md"></div>
                                            <ul className="post-meta clearfix">
                                                {/* <li>{getdata.date}</li> */}
                                                {/* <li>
                                                    <a><i className="fa fa-heart-o" aria-hidden="true"></i>{getdata.like}</a>
                                                </li>
                                                <li>
                                                    <a><i className="fa fa-comment-o" aria-hidden="true"></i>{getdata.total_comment}</a>
                                                </li> */}
                                            </ul>
                                            <div className="post-text">
                                                <h4>Privacy Policy</h4>
                                                <div className="spce sm"></div>
                                                <br />
                                                <p>1. Outz only collects personal information for the purposes of conducting our business as an online retailer and trader. The personal information we collect from you will include information you give us when you:</p>
                                                <p>- register or subscribe to our app or website;</p>
                                                <p>- complete a transaction with us;</p>
                                                <p>- contact us.</p>
                                                <p>2. The personal information that we collect on individuals may include, but is not limited to:</p>
                                                <p>- your name;</p>
                                                <p>- your contact details including your email, home address and billing address;</p>
                                                <p>- your payment details.</p>
                                                <p>3. Outz will not Collect Personal Information secretly or in an underhanded way and will not sell your Personal Information to any third party.</p>
                                                <p>4. Outz may collect personal information from individuals who are not customers of our business but whose personal information is given to us by those individuals via our Website or in the course of a transaction.</p>
                                                <p>4.When you visit our Website and/or download our app, third parties, may place cookies on your browser to enhance and manage our website and improve our business and the services we provide to you. We and/or Google may use this information to optimise and place advertisements, including advertisements of third-party vendors, on our own and third-party websites. Google’s ability to use and share information collected by Google Analytics is restricted by the Google Analytics Terms of Use and Privacy Policy. By using the website and agreeing to this policy, you grant us permission to place and store cookies on your browser. Cookies may be used by us to collect the following information:</p>
                                                <p>- your computer’s operating system;</p>
                                                <p>- your computer’s browser type and capabilities;</p>
                                                <p>- your computer’s Internet Protocol (IP) address and geolocation;</p>
                                                <p>- web pages visited, including how you were referred to each web page; and</p>
                                                <p>- web page usage statistics, including the time spent on each web page.</p>
                                                <p>5 Cookies can be managed by accessing the individual settings in your browser.</p>
                                                <p>6 We will not identify users or their browsing activities, except where required by law or in accordance with our Terms and Conditions of Use and Sale.</p>
                                                <p>7 When downloading our app, you will get a window message that tells you what content on your smartphone the app will need access to maps, location and calendar. You can pick and choose which content a particular app can have access to under Settings / Apps / App Permission.</p>
                                                <p>8. When registering with Outz, you consent to Outz using your personal information, such as your email address, for direct marketing purposes. This includes sending you special deals emails. You may opt-out of receiving these direct marketing communications at any time.</p>
                                                <p>9.  We use and disclose your personal information for the purpose of providing you with the goods or services that you have requested, or otherwise to enable us to carry out our business as an online retailer of goods and services.
7. We take steps to protect the personal information we hold against loss, unauthorised access, use, modification or disclosure and against other misuses. When no longer required, personal information is destroyed in a secure manner or deleted.</p>
                                                {/* <div className="feature-text bg-light text-center light">
                                                    <div className="icon-md grad-yellow">
                                                        <span className="fa fa-quote-left clip-txt" aria-hidden="true"></span>
                                                    </div>
                                                    <p>Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted</p>
                                                </div>
                                                <p>Absolutely! We work with Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted ipsum dolor siridens eligendi Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ever since the 1500s.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spce md"></div>
                                    {/* <Comments />    */}
                                </div>
                                {/*** Sidebar **/}
                                {/* <Sidebar /> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Privacy
