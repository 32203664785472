import React, { Component } from "react";

import Footer from "../global-components/footer";
import PageHeader from "../global-components/page-header";
import Security from "../images/Security.svg";
import Sidebar from "../blog-components/sidebar";
import f1 from "../images/f1.svg";
import f2 from "../images/f2.svg";
import f22 from "../images/f22.svg";
import f3 from "../images/f3.svg";
import f33 from "../images/f33.svg";
import f4 from "../images/f4.svg";
import f44 from "../images/f44.svg";
import f5 from "../images/f5.svg";
import f55 from "../images/f55.svg";
import f6 from "../images/f6.svg";
import f66 from "../images/f66.svg";
import homedata from "../../data/home.json";

// import f1 from "../images/f1.svg";
// import f1 from "../images/f1.svg";

// import feature-1 from '../../'

class Feature_V1 extends Component {
	render() {
		let getData = homedata.featuresv1;
		let publicUrl = process.env.PUBLIC_URL + "/";

		return (
			<div>
				<section className='sec-pad-lg pb-0'>
					<div className='row'>
						<div className='container sec-pad-lg pb-0'>
							<div
								id='feature'
								className='col-sm-6 col-md-6 col-lg-6'
								// style={{ height: "750px" }}
							>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f1}
										// style={{ height: "600px" }}
										slt=''
									/>
								</div>
							</div>

							<div className='col-sm-6'>
								{/* <div className='container'> */}
								{/* <div className='row text-center'> */}
								<div
									className='overview-img wow animated fadeInUp'
									data-wow-duration='1.2s'
									data-wow-delay='.2s'
								>
									<div className='text-center'>
										<h4
											className='text-red'
											style={{
												// height: "600px",
												marginTop: "50%",
											}}
										>
											How it works
										</h4>
										<hr
											className='text-red'
											style={{ border: "2px solid red" }}
										/>
										<h5 className='text-black font-normal'>
											Discouver your city's best local
											business with up to 80% off!
											Experience More. Save More.
										</h5>
									</div>
								</div>
							</div>
						</div>

						{/* </div> */}
						{/* </div> */}
					</div>

					<div className='row mt-20'>
						<div className='container sec-pad-lg pb-0'>
							<div
								className='col-sm-4'
								style={{ height: "540px" }}
							>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										className='center-items'
										src={f2}
										//
										slt=''
									/>
								</div>
							</div>
							<div
								className='overview-img wow animated fadeInUp'
								data-wow-duration='1.2s'
								data-wow-delay='.2s'
							>
								<div className='col-sm-4'>
									<div
										className='overview-img wow animated fadeInUp'
										data-wow-duration='1.2s'
										data-wow-delay='.2s'
									>
										<div>
											<h3
												className='text-black text-center'
												style={{ marginTop: "50%" }}
											>
												EXPLORE
											</h3>
										</div>
										<h5
											className='text-black font-normal text-center'
											// style={{ fontWeight: "lighter" }}
										>
											Browse a list of local business that
											offers best deals!
										</h5>
									</div>
								</div>
								<div className='col-sm-4 center-items'>
									<div
										className='overview-img wow animated fadeInUp'
										data-wow-duration='1.2s'
										data-wow-delay='.2s'
									>
										<img
											src={f22}
											// style={{ height: "500px" }}
											slt=''
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row mt-20'>
						<div className='container sec-pad-lg pb-0'>
							<div
								className='col-sm-4 center-items'
								style={{ height: "540px" }}
							>
								<div
									className='wow animated fadeInUp '
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f3}
										// style={{ height: "500px" }}
										slt=''
									/>
								</div>
							</div>
							<div className='col-sm-4'>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<div>
										<h3
											className='text-black text-center'
											style={{ marginTop: "50%" }}
										>
											FILTER
										</h3>
									</div>
									<h5
										className='text-black font-normal text-center'
										// style={{ fontWeight: "lighter" }}
									>
										Outz will give you access to savings on
										Dining, Karaoke, Beauty Therapy,Internet
										Cafes' and much more!
									</h5>
								</div>
							</div>
							<div className='col-sm-4 center-items'>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f33}
										style={{ height: "500px" }}
										slt=''
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='row mt-20'>
						<div className='container sec-pad-lg pb-0'>
							<div
								className='col-sm-4 center-items'
								style={{ height: "540px" }}
							>
								<div
									className='wow animated fadeInUp center-items'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f4}
										// style={{ height: "500px" }}
										slt=''
									/>
								</div>
							</div>
							<div className='col-sm-4'>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<div>
										<h3
											className='text-black text-center'
											style={{ marginTop: "50%" }}
										>
											FILTER
										</h3>
									</div>
									<h5
										className='text-black font-normal text-center'
										// style={{ fontWeight: "lighter" }}
									>
										We offer a range of categories you can
										choose from, and you can choose the
										latest business list on Outz or the ones
										that are closest to you.
									</h5>
								</div>
							</div>
							<div className='col-sm-4 center-items'>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f44}
										style={{ height: "500px" }}
										slt=''
									/>
								</div>
							</div>
						</div>
						<div className='row mt-20'>
							<div className='container sec-pad-lg pb-0'>
								<div
									className='col-sm-4 center-items'
									style={{ height: "540px" }}
								>
									<div
										className='wow animated fadeInUp'
										data-wow-duration='2s'
										data-wow-delay='.2s'
									>
										<img
											src={f5}
											// style={{ height: "500px" }}
											slt=''
										/>
									</div>
								</div>
								<div className='col-sm-4'>
									<div
										className='wow animated fadeInUp'
										data-wow-duration='2s'
										data-wow-delay='.2s'
									>
										<div>
											<h3
												className='text-black text-center'
												style={{ marginTop: "50%" }}
											>
												ORDER A DEAL
											</h3>
										</div>
										<h5
											className='text-black font-normal text-center'
											// style={{ fontWeight: "lighter" }}
										>
											Once you have found a deal you like,
											order it and redeem at the venue
										</h5>
									</div>
								</div>
								<div className='col-sm-4 center-items'>
									<div
										className='wow animated fadeInUp'
										data-wow-duration='2s'
										data-wow-delay='.2s'
									>
										<img
											src={f55}
											// style={{ height: "500px" }}
											slt=''
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row mt-20'>
						<div className='container sec-pad-lg pb-0'>
							<div
								className='col-sm-4 center-items'
								style={{ height: "540px" }}
							>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f6}
										// style={{ height: "500px" }}
										slt=''
									/>
								</div>
							</div>
							<div className='col-sm-4'>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<div>
										<h3
											className='text-black text-center'
											style={{ marginTop: "50%" }}
										>
											REDEEM
										</h3>
									</div>
									<h5
										className='text-black font-normal text-center'
										// style={{ fontWeight: "lighter" }}
									>
										Go to 'My Voucher', click 'Redeem', and
										show your QR code at the venue, they
										will scan your OR code and complete the
										purchase.
									</h5>
								</div>
							</div>
							<div className='col-sm-4 center-items'>
								<div
									className='wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<img
										src={f66}
										// style={{ height: "500px" }}
										slt=''
									/>
								</div>
							</div>
						</div>
					</div>

					{/* <div className='col-md-10 col-md-offset-1'>
								<div
									className='text-center wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.2s'
								>
									<h4>{getData.title}</h4>
								</div>
								<div className='spce'></div>
								<div
									className='items feat-op row grad-yellow text-center wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.4s'
								>
									{getData.facts.map((element, i) => {
										return (
											<div
												key={i}
												className='col-sm-2 res-margin-sm'
											>
												<div className='icon-md'>
													<span
														className={
															element.icon +
															" clip-txt"
														}
														aria-hidden='true'
													></span>
												</div>
												<div className='text'>
													<h6 className='fw-400 mg-0'>
														{element.title}
													</h6>
												</div>
											</div>
										);
									})}
								</div>
								<div
									className='bg-mockup text-center wow animated fadeInUp'
									data-wow-duration='2s'
									data-wow-delay='.6s'
								>
									<img src={getData.image} />
								</div>
							</div> */}
					{/* </div> */}
					{/* </div> */}
					{/* <img src={Security} style={{ height: "600px" }} slt='' /> */}
				</section>
				<Footer />
			</div>
		);
	}
}

export default Feature_V1;
