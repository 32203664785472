import React, { Component } from 'react';
import homedata from '../../data/home.json';



class App_Download extends Component {

    render() {
        
        let data = homedata.downloadApps
        let publicUrl = process.env.PUBLIC_URL+'/'
		let colorscheme  = this.props.colorscheme ? this.props.colorscheme : 'grdnt-yellow';

		const inlineStyle = {
            backgroundImage: 'url('+publicUrl+data.bgImg+')'
        }
        
        return  <section className={colorscheme}>
		<div className="parallax-bg sec-pad-lg cta" data-stellar-background-ratio="0.5" style={inlineStyle}>
			<div className="container">
				<div className="row">
					<div className="light grad-yellow wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
						<h3>{data.title}</h3>
						<p className="large">{data.desc}</p>
						<div className="btn-holder">
							<div className="btn-holder">
							{
								data.buttons.map( ( element, i ) => {
									return(	
									<a key={i} href={element.url}><img src={publicUrl+element.img}/></a>	
									)
									
								})
							}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default App_Download
