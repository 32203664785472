import React from 'react';
import Footer from './global-components/footer';
import HeroV3 from './home-components/hero-v3';
import Services from './home-components/services';
import OverviewV3 from './home-components/overview-v3';
import OverviewV4 from './home-components/overview-v4';
import Screenshot from './home-components/screenshots';
import Pricing from './home-components/pricing';
import TestimonialV2 from './home-components/testimonial-v2';
import App_Download from './home-components/app-download';
import Video from './home-components/video';
import HowWorks from './home-components/how-works';
import Team from './home-components/team';
import Blog from './home-components/blog';
import FaqV2 from './home-components/faq-v2';
import Contact from './home-components/contact';

const Home = () => {
    return <div>
        <HeroV3 />
        <Services />
        <OverviewV3 />
        <OverviewV4 />
        <Video />
        <HowWorks />
        <Screenshot colorscheme="grdnt-blue" />
        <Pricing SectionColorScheme="grad-blue" tableColorScheme="grdnt-blue" colorscheme="grdnt-blue" />
        <FaqV2 />
        <TestimonialV2 />
        <Team />
        <Blog bgColorScheme="bg-gray" colorscheme="grad-blue" BorderColorScheme="grdnt-blue" />
        <App_Download colorscheme="grdnt-blue" />
        <Contact />
        <Footer colorscheme="grdnt-blue" />
    </div>
}


export default Home
