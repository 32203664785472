import axios from "axios";

const urladd = "https://api.ieats.com.au/api";

export var recordShare = async (userid, property, app, web, page, device) => {
	let formData = new FormData();
	formData.append("userid", userid);
	formData.append("property", property);
	formData.append("app", app);
	formData.append("web", web);
	formData.append("page", page);
	formData.append("device", device);
	formData.append("installed", 0);
	let result = await axios({
		method: "post",
		url: urladd + "/app.php?s=App.Shared.NewShared",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	console.log(result);
	return result;
};

//申请成为商家接口
//App.Applyresuser.NewApplyresuser
export var NewApplyresuser = async (
	userid,
	resname,
	resaddress,
	contact,
	cityid,
	beizhu
) => {
	let formData = new FormData();
	formData.append("userid", userid);
	formData.append("resname", resname);
	formData.append("resaddress", resaddress);
	formData.append("contact", contact);
	formData.append("cityid", cityid);
	formData.append("beizhu", beizhu);
	// formData.append("installed", 0);
	let result = await axios({
		method: "post",
		url:
			"https://ios.outz.com.au/api/app.php?s=App.Applyresuser.NewApplyresuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	console.log(result);
	return result;
};
