import React, { Component } from 'react';
import CountUp  from 'react-countup';
import homedata from '../../data/home.json';

class Counter extends Component {
    render () {

        let getData = homedata.counter
        
        return <section className="counter-section">
		<div className="container">
			<div className="row">
				<div className="items sec-pad-sm row brdr-btm grad-yellow right-dir-col res-center-sm wow animated fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
					{
						getData.items.map( ( element, i ) => {
							return(
								<div key={i} className="col-sm-3 res-margin-sm">
									<div className="icon-md">
										<span className={element.icon + ' clip-txt'} aria-hidden="true"></span>
									</div>
									<div className="text">
										<h4 className="counter mg-0"><CountUp end={element.number} duration={10} /></h4>
										<p>{element.title}</p>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	</section>
    }
}


export default Counter
