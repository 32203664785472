import React, { Component } from 'react';
import Footer from '../global-components/footer';
import Comments from './comments'
import PageHeader from '../global-components/page-header'
import Sidebar from '../blog-components/sidebar';
import singleBlogdata from '../../data/single-blogdata'

class Terms extends Component {
    render() {

        // let getdata = singleBlogdata.singlepostdata;
        let publicUrl = process.env.PUBLIC_URL + '/'

        return (
            <div>
                <div id="home" className="hero-single grdnt-yellow style-wave-2 bg-trans-2">
                    <div className="container">
                        <div className="row text-center">
                            <div className="intro-text light">
                                <h3>Terms and Conditions</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="blog" className="sec-pad blog">
                    <div className="container">
                        <div className="row">
                            <div className="post row">
                                <div className="col-md-12 col-sm-12 res-margin">
                                    <div className="post-col">
                                        {/* <img src={publicUrl+getdata.image}/> */}
                                        <div className="post-content">
                                            <div className="spce md"></div>
                                            <ul className="post-meta clearfix">
                                                {/* <li>{getdata.date}</li> */}
                                                {/* <li>
                                                    <a><i className="fa fa-heart-o" aria-hidden="true"></i>{getdata.like}</a>
                                                </li>
                                                <li>
                                                    <a><i className="fa fa-comment-o" aria-hidden="true"></i>{getdata.total_comment}</a>
                                                </li> */}
                                            </ul>
                                            <div className="post-text">
                                                <h4>Terms and Conditions</h4>
                                                <div className="spce sm"></div>
                                                <br />
                                                <p>1. Outz(The Company) does not accept any liability from the sale or consumption of food, beverages or services from any vendors.</p>
                                                <p>2. You must be registered to make orders and access certain features on our app or website.</p>
                                                <p>3. When you register and activate your account, you will provide us with personal information such as your name, address, date of birth, email address and telephone number. You must ensure that this information is accurate and current.</p>
                                                <p>4. If you are under the age of 13 years, you may not create an account or register as a member. If you are 13 or older but under the age of 18, you represent that you have reviewed these Terms with your parent or legal guardian to make sure that you and your parent or legal guardian understand these Terms. If you are a parent or guardian permitting a person under the age of 18 (a Minor) to create an account, you agree to:</p>
                                                <p> a) exercise supervision over the Minor's use of our Services and account;</p>
                                                <p> b) assume all risks associated with the Minor's use of our Services and their account, including the transmission of content or information to and from third parties via the Internet;</p>
                                                <p> c) ensure that the content and information that the Minor may encounter in using our Services are suitable for the Minor;</p>
                                                <p> d) assume liabilities resulting from the Minor's use of our Services and their account;</p>
                                                <p> e) ensure the accuracy and truthfulness of all information submitted by the Minor; and</p>
                                                <p> f) provide the consents contained in these Terms on behalf of the Minor.</p>
                                                <p>5. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure.</p>
                                                <p>6. We are not liable for any unauthorised use of your account.</p>
                                                <p>7. By using OUTZ and its associated functionality, you grant your express consent to us to send you direct marketing and your consent to receive certain communication may be implied from the use of certain functional aspects of our service, such as receiving reminders.</p>
                                                {/* <div className="feature-text bg-light text-center light">
                                                    <div className="icon-md grad-yellow">
                                                        <span className="fa fa-quote-left clip-txt" aria-hidden="true"></span>
                                                    </div>
                                                    <p>Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted</p>
                                                </div>
                                                <p>Absolutely! We work with Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted ipsum dolor siridens eligendi Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ever since the 1500s.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spce md"></div>
                                    {/* <Comments />    */}
                                </div>
                                {/*** Sidebar **/}
                                {/* <Sidebar /> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Terms
