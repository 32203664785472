import About from "./home-components/about";
import AppDownload from "./home-components/app-download";
import Blog from "./home-components/blog";
import CTA from "./home-components/cta";
import Contact from "./home-components/contact";
import Faq from "./home-components/faq";
import FeatureV1 from "./home-components/feature-v1";
import FeatureV2 from "./home-components/feature-v2";
import Footer from "./global-components/footer";
import HeroV1 from "./home-components/hero-v1";
import Overview from "./home-components/overview";
import OverviewV2 from "./home-components/overview-v2";
import Pricing from "./home-components/pricing";
import React from "react";
import Screenshot from "./home-components/screenshots";
import Testimonial from "./home-components/testimonial";

const Home = () => {
	return (
		<div>
			<HeroV1 />
			<FeatureV1 />
			{/* <FeatureV1 />
        <FeatureV2 />
        <CTA />
        <About />
        <Overview />
        <OverviewV2 />
        <Screenshot />
        <Pricing />
        <Testimonial />
        <AppDownload />
        <Blog />
        <Faq /> */}
			{/* <Contact /> */}
			{/* <Footer style={{ marginTop: "0px" }} /> */}
		</div>
	);
};

export default Home;
