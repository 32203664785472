import {
	BrowserRouter,
	Route,
	BrowserRouter as Router,
	Switch,
} from "react-router-dom";
import React, { Component } from "react";

import Blog from "./components/blog-components/blog";
import Blogdata from "./data/blog";
import Feature_V1 from "./components/home-components/feature-v1";
import Home from "./components/home";
import Home_v2 from "./components/home-v2";
import PaymentSuccess from "./components/paysuccess";
import PaymentFail from "./components/payfail";
import Home_v3 from "./components/home-v3";
import Home_v4 from "./components/home-v4";
import Navbar from "./components/global-components/navbar";
import Privacy from "./components/blog-components/privacy";
import ReactDOM from "react-dom";
import SingleBlog from "./components/blog-components/single";
import Terms from "./components/blog-components/terms";

class Root extends Component {
	render() {
		return (
			<Router>
				<BrowserRouter basename='/'>
					<div>
						<Navbar />
						<Switch>
							<Route exact path='/' component={Home} />
							<Route exact path='/download' component={Home_v2} />
							<Route exact path='/paymentsuccess' component={PaymentSuccess} />
							<Route exact path='/paymentfail' component={PaymentFail} />
							<Route exact path='/home-v3' component={Home_v3} />
							<Route exact path='/home-v4' component={Home_v4} />
							<Route
								path='/blog'
								render={() => {
									return <Blog data={Blogdata} />;
								}}
							/>
							<Route
								exact
								path='/blog-single'
								component={SingleBlog}
							/>
							<Route exact path='/privacy' component={Privacy} />
							<Route exact path='/terms' component={Terms} />
							<Route
								exact
								path='/feature'
								component={Feature_V1}
							/>
						</Switch>
					</div>
				</BrowserRouter>
			</Router>
		);
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("outzapp"));
