import React, { Component } from "react";

import FooterData from "../../data/footerdata";
import Subscribe from "../home-components/subscription";
import homedata from "../../data/home.json";

class Footer extends Component {
	render() {
		let getData = homedata.herov1;

		let publicUrl = process.env.PUBLIC_URL + "/";
		let colorscheme = this.props.colorscheme
			? this.props.colorscheme
			: "grdnt-yellow";

		return (
			<footer
				className={colorscheme + " footer-wrapper pb-0 sec-pad light"}
			>
				<div className='container footer-content'>
					<div className='row'>
						{/*** Subscribe ***/}
						{/* <Subscribe /> */}
						<div
							className='btn-holder center-items'
							style={{ marginBottom: "30px" }}
						>
							{getData.button.map((element, i) => {
								return (
									<a
										key={i}
										href={
											i === 2
												? require("../../data/app-release.apk")
												: element.btn_url
										}
									>
										<img
											src={publicUrl + element.btn_img}
											style={{
												marginBottom: "10px",
											}}
										/>
									</a>
								);
							})}
						</div>

						<div className='row footer-widget'>
							<div className='col-md-3 col-sm-6 res-margin'>
								<a
									className='navbar-brand'
									href={FooterData.widgetAbout.url}
								>
									<img
										alt={FooterData.widgetAbout.altText}
										src={
											publicUrl +
											FooterData.widgetAbout.image
										}
									/>
								</a>
								<div className='spce'></div>
								<p>{FooterData.widgetAbout.desc}</p>
							</div>
							<div className='col-md-2 col-sm-6 col-md-offset-1 res-margin'>
								<h6>Email:</h6>
								<a href='mailto:support@outz.net.au'>
									support@outz.net.au
								</a>
								{/* <p>support@outz.net.au</p> */}
								{/* <h6>
									{FooterData.widgetUsefulLinks.widgetTitle}
								</h6>
								<div className='spce'></div>
								<ul>
									{FooterData.widgetUsefulLinks.widgetLink.map(
										(data, i) => {
											return (
												<li key={i}>
													<a
														href={data.url}
														target='_blank'
													>
														{data.title}
													</a>
												</li>
											);
										}
									)}
								</ul> */}
							</div>
							<div className='col-md-3 col-sm-6 res-margin'>
								{/* <h6>{FooterData.widgetSupport.widgetTitle}</h6>
								<div className='spce'></div>
								<ul>
									{FooterData.widgetSupport.widgetLink.map(
										(data, i) => {
											return (
												<li key={i}>
													<a
														href={data.url}
														target='_blank'
													>
														{data.title}
													</a>
												</li>
											);
										}
									)}
								</ul> */}
							</div>
							<div className='col-md-3 col-sm-6'>
								<h6>
									{FooterData.widgetSocialicon.widgetTitle}
								</h6>
								<div className='spce'></div>
								<ul className='social-holder light'>
									{FooterData.widgetSocialicon.socialMedia.map(
										(data, i) => {
											return (
												<li key={i}>
													<a
														href={data.url}
														target='_blank'
													>
														<i
															className={
																data.icon
															}
															aria-hidden='true'
														></i>
													</a>
												</li>
											);
										}
									)}
								</ul>
							</div>
						</div>
						<div className='spce md'></div>
						<div className='spce'></div>
						<div
							className='copyright text-center light'
							dangerouslySetInnerHTML={{
								__html: FooterData.copyrighttext,
							}}
						></div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
