import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Feature_V2 extends Component {
    render () {

		let getData = homedata.featurev2
		let colorscheme  = this.props.colorscheme ? this.props.colorscheme : 'grdnt-yellow';

        return <section id="feature" className="bg-memphis sec-pad-lg">
		<div className="container">
			<div className="row">
				<div className="section-text grad-yellow text-center  wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{getData.sub_title}</p>
					<h3>{getData.title}</h3>
				</div>
				<div className="items row">
					<div className="text-center col-feat shad-item">

						{
							getData.features.map( ( element, i ) => {
								return(
								<div key={i} className="col-md-3 col-sm-6 res-margin wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
									<div className="item">
										<div className={colorscheme + ' icon-bg-lg bg-rad-anim' }>
											<span className={element.icon}></span>
										</div>
										<div className="spce"></div>
										<h5>{element.title}</h5>
										<p>{element.desc}</p>
									</div>
								</div>
								)
							})
						}

					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Feature_V2
