import React, { Component } from 'react';
import homedata from '../../data/home.json';

class CTA extends Component {
    render () {

		let getData = homedata.cta
		let publicUrl = process.env.PUBLIC_URL+'/'


        return <section className="cta sec-pad-lg pt-0">
		<div className="container">
			<div className="row">
				<div className="col-md-6 col-md-offset-3 text-center  wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<img alt="" src={publicUrl+getData.image} />
					<div className="spce"></div>
					<h4>{getData.numbertext}</h4>
					<p>{getData.desc}</p>
					<div className="spce"></div>
					<a className="btn grdnt-yellow" href={getData.btn_url}><span>{getData.btn_text}</span></a>
				</div>
			</div>
		</div>
	</section>  
    }
}


export default CTA
