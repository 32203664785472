import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import homedata from '../../data/home.json';

class Testimonial extends Component {
    render() {

	let getData = homedata.testimonial
    let publicUrl = process.env.PUBLIC_URL+'/'


    // owl Options
    const options = {
		loop:true,
		autoplayTimeout:3500,
		items:1,
		nav: false,
		navText: [],
		margin:20
	};


        return <section className="sec-pad-lg pt-0">
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="col-md-5 wow animated fadeInUp res-margin" data-wow-duration="1s" data-wow-delay=".2s">
						<p className="meta-head">{getData.sub_title}</p>
						<h3>{getData.title}</h3>
					</div>
					<OwlCarousel className="owl-carousel grad-yellow col-md-7 quote wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s" options={options}>
					
					{
						getData.testimonial.map( ( element, i ) => {
							return(
							<div key={i} className="quote-item">
								<div className="quote-text bg-gray">
									<div className="rating">
										<ul className="rating-list">
											<li><i className="fa fa-star" aria-hidden="true"></i></li>
											<li><i className="fa fa-star" aria-hidden="true"></i></li>
											<li><i className="fa fa-star" aria-hidden="true"></i></li>
											<li><i className="fa fa-star" aria-hidden="true"></i></li>
											<li><i className="fa fa-star" aria-hidden="true"></i></li>
											<li><p>({element.star})</p></li>
										</ul>
									</div>
									<p>{element.desc}</p>
									<div className="spce"></div>
									<div className="profile clearfix">
										<h6 className="heading">{element.name}</h6>
										<p>{element.position}</p>
									</div>
								</div>
								<div className="profile-img"><img src={publicUrl+element.img} alt={element.imgAlt}/></div>
							</div>
							)
						})
					}
												
					</OwlCarousel>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Testimonial
