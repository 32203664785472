import React, { Component } from 'react';
import homedata from '../../data/home.json';

class HowWorks extends Component {
    render() {

        let getData = homedata.howWorks

        return 	<section id="How" className="sec-pad-lg">
		<div className="container">
			<div className="row">
				<div className="section-text text-center  wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{getData.sub_title}</p>
					<h3>{getData.title}</h3>
				</div>
				<div className="items row grad-blue process">
					<div className="text-center icon-shad">

					{
						getData.workProcess.map( ( element, i ) => {
							return(
							<div key={i} className="col-sm-4 res-margin-sm wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
								<div className="item">
									<div className={ element.color + ' icon-bg-md' }>
										<span>{element.number}</span>
									</div>
									<div className="spce"></div>
									<h5>{element.title}</h5>
									<p>{element.desc}</p>
								</div>
							</div>
							)
						})
					}
						
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default HowWorks
