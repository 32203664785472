import React, { Component } from "react";

import pageHeaderData from "../../data/page-header";

// class PageHeader extends Component {
//     render() {

//         let getData = pageHeaderData
//         return (

//         )
//     }
// }

// export default PageHeader;

// import React from "react";

const PageHeader = ({ pageTitle }) => {
	let getData = pageHeaderData;
	return (
		<div>
			<div
				id='home'
				className='hero-single grdnt-yellow style-wave-2 bg-trans-2'
			>
				<div className='container'>
					<div className='row text-center'>
						<div className='intro-text light'>
							<h3>{pageTitle}</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageHeader;
