import React, { Component } from 'react';
import PageHeader from '../global-components/page-header'
import Footer from '../global-components/footer';
import Sidebar from '../blog-components/sidebar';


class Blog extends Component {
    render () {

        let getProps = this.props.data
        let getBlogData = getProps.blogs
        let publicUrl = process.env.PUBLIC_URL+'/'
        
        return (
            <div>
            <PageHeader />
            <section id="blog" className="sec-pad blog">
                <div className="container">
                    <div className="row">
                        <div className="post row grad-yellow">
                            <div className="col-md-8 col-sm-8 res-margin">
                            {
                            getBlogData.map( function( data, i ) {
                                return (
                                    <div key={i} className="post-col">
                                        <a  href={data.permalink}><img src={publicUrl+data.image}/></a>
                                        <div className="post-content">
                                            <div className="spce md"></div>
                                            <ul className="post-meta clearfix">
                                                <li>{data.date}</li>
                                                <li>
                                                    <a href={data.permalink}><i className="fa fa-heart-o" aria-hidden="true"></i>{data.like}</a>
                                                </li>
                                                <li>
                                                    <a href={data.permalink}><i className="fa fa-comment-o" aria-hidden="true"></i>{data.total_comment}</a>
                                                </li>
                                            </ul>
                                            <div className="post-text">
                                                <a href={data.permalink}> 
                                                    <h4>{data.title}</h4>
                                                    <div className="spce sm"></div>
                                                    <p>{data.content}</p>
                                                </a>
                                                <div className="spce sm"></div>
                                                <a className="btn grdnt-yellow fill" href={data.permalink}><span className="clip-txt">Read More</span></a>
                                            </div>
                                        </div>
                                        <div className="spce lg"></div>
                                    </div>
                                )
                                })
                            }
                                                                
                            </div>
                            {/*** Sidebar ***/}
                            <Sidebar />                       
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
        )
    }
}


export default Blog
