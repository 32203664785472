import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Team extends Component {

    render () {
        let getData = homedata.team
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
		<div id="team" className="team sec-pad-lg  grad-prple">
		<div className="container">
			<div className="row">
				<div className="section-text text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{getData.sub_title}</p>
					<h3>{getData.title}</h3>
				</div>
				<div className="team-member-profile text-center wow animated fadeInUp" data-wow-duration="1.4s" data-wow-delay=".2s">
					
				{
					getData.members.map( ( element, i ) => {
						return(
							<div key={i} className="col-md-3 col-sm-6">
								<div className="team-mem-col res-margin">
									<div className="link">
										<div className="team-hover">
											<div className="team-hover-content">
												<ul className="extra-link">
													{
														element.social.map( ( element, i ) => {
															return(
																<li key={i} ><a className="grdnt-blue" href={element.url}><i className={element.icon} aria-hidden="true"></i></a></li>
															)
														})
													}
													
												</ul>
											</div>
										</div>
										<div className="image">
											<img src={publicUrl+element.image} />
										</div>
									</div>
									<div className="spce"></div>
									<div className="member-name">
										<h6>{element.title}</h6>
										<p className="meta">{element.position}</p>
									</div>							
								</div>
							</div>
						)
					})
				}

				</div>
			</div>
		</div>
	</div>
    )
    }
}


export default Team
