import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Services extends Component {

    render () {
		let getData = homedata.services
		
        return (
        <section id="feature" className="sec-pad-sm pb-0">
		<div className="container">
			<div className="row">
				<div className="section-text text-center  wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{getData.sub_title}</p>
					<h3>{getData.title}</h3>
				</div>
				<div className="items row grad-blue">
					<div className="text-center col-feat shad-item icon-shad">

					{
						getData.items.map( ( element, i ) => {
							return(
								<div key={i} className="col-md-3 col-sm-6 res-margin wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
									<div className="item">
										<div className="icon-md">
											<span className={element.icon+ ' '+element.iconClass}></span>
										</div>
										<div className="spce"></div>
										<h5>{element.title}</h5>
										<p>{element.desc}</p>
									</div>
								</div>
							)
						})
					}
					</div>
				</div>
			</div>
		</div>
	</section>
    )
    }
}


export default Services
