import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Hero_V3 extends Component {

    render () {
        let getData = homedata.herov3
		let publicUrl = process.env.PUBLIC_URL+'/'
		let imgAlt = "image"

        return (
        <div id="home" className="hero grdnt-blue style-angle bg-trans-1">
			<div className="container">
				<div className="row hero-content">
					<div className="row">
						<div className="col-sm-6">
							<div className="intro-text light">
								<h1>{getData.title}</h1>
								<div className="spce"></div>
								<p className="large">{getData.desc}</p>
								<div className="btn-holder">
								{
									getData.button.map( ( element, i ) => {
										return(
											<a key={i} href={element.btn_url}><img src={publicUrl+element.btn_img} alt={imgAlt} /></a>
										)
									})
								}
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="img-pre">
								<img className="jump-anim" src={publicUrl+getData.mockup_img} alt={imgAlt} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
    }
}


export default Hero_V3
