import React from 'react';
import Footer from './global-components/footer';
import Success from './home-components/success';

class Home extends React.Component {
    componentDidMount = async () => {
    }

    render() {
        return <div>
            <Success />
            <Footer />
        </div>
    }

}

export default Home
