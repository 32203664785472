import React, { Component, useState } from "react";

import Comments from "./comments";
import Footer from "../global-components/footer";
import { NewApplyresuser } from "../api/api";
import PageHeader from "../global-components/page-header";
import Sidebar from "../blog-components/sidebar";
import singleBlogdata from "../../data/single-blogdata";

const SingleBlog = () => {
	let getdata = singleBlogdata.singlepostdata;
	let publicUrl = process.env.PUBLIC_URL + "/";

	const [userid, setUserid] = useState("");

	const [bName, setBusinessName] = useState("");

	const [add, setAdd] = useState("");

	const [city, setCity] = useState("");

	const [phone, setPhone] = useState("");
	const [beizhu, setBeizhu] = useState("");

	const handleSend = async () => {
		const data = await NewApplyresuser(
			userid,
			bName,
			add,
			city,
			phone,
			beizhu
		);
		// console.log(data);
	};

	return (
		<div>
			<PageHeader />
			<section id='blog' className='sec-pad blog'>
				<div className='container'>
					<div className='row '>
						<div className='col-md-12 col-sm-12 justify-content-center'>
							<div className='form-area custom-form'>
								<h5>I am a business owner</h5>
								<div className='spce'></div>
								<div className='row'>
									<div className='form-group col-md-12'>
										<input
											name='name'
											className='form-control'
											placeholder='* Business Name'
											type='text'
											onChange={(e) =>
												setBusinessName(e.target.value)
											}
										/>
									</div>
									<div className='form-group col-md-12'>
										<input
											name='address'
											className='form-control'
											placeholder='* Address'
											type='text'
											onChange={(e) =>
												setAdd(e.target.value)
											}
										/>
									</div>
									<div className='form-group col-md-12'>
										<input
											name='city'
											className='form-control'
											placeholder='* City'
											type='text'
											onChange={(e) =>
												setCity(e.target.value)
											}
										/>
									</div>

									<div className='form-group col-md-12'>
										<input
											name='phone number'
											className='form-control'
											placeholder='Phone number'
											type='text'
											onChange={(e) =>
												setPhone(e.target.value)
											}
										/>
									</div>
									<div className='col-md-12 '>
										<button
											className='btn grdnt-yellow  d-flex justify-content-center'
											onClick={handleSend}
										>
											Send
										</button>
									</div>
								</div>
							</div>
						</div>
						{/* <div className='post row'>
								<div className='col-md-8 col-sm-8 res-margin'> */}
						{/* <div className="post-col">
                                    <img src={publicUrl+getdata.image}/>
                                    <div className="post-content">
                                        <div className="spce md"></div>
                                        <ul className="post-meta clearfix">
                                            <li>{getdata.date}</li>
                                            <li>
                                                <a><i className="fa fa-heart-o" aria-hidden="true"></i>{getdata.like}</a>
                                            </li>
                                            <li>
                                                <a><i className="fa fa-comment-o" aria-hidden="true"></i>{getdata.total_comment}</a>
                                            </li>
                                        </ul>
                                        <div className="post-text">
                                                <h4>{getdata.title}</h4>
                                                <div className="spce sm"></div>
                                                <p>Absolutely! We work with Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted ipsum dolor siridens eligendi Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ever since the 1500s.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                                <div className="feature-text bg-light text-center light">
                                                    <div className="icon-md grad-yellow">
                                                        <span className="fa fa-quote-left clip-txt" aria-hidden="true"></span>
                                                    </div> 
                                                    <p>Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted</p>
                                                </div>
                                                <p>Absolutely! We work with Stripe which guarantees your safety and security. All site communication and data transfer are secured and encrypted ipsum dolor siridens eligendi Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ever since the 1500s.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                        </div>
                                    </div>
                                </div> */}
						{/* <div className='spce md'></div> */}
						{/* </div> */}
						{/*** Sidebar **/}
						{/* <Sidebar />                      */}
						{/* </div> */}
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default SingleBlog;
