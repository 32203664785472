import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Pricing extends Component {
    render () {

		let getData = homedata.pricingTable
		let SectionColorScheme  = this.props.SectionColorScheme ? this.props.SectionColorScheme : 'grad-yellow';
		let tableColorScheme  = this.props.tableColorScheme ? this.props.tableColorScheme : 'bg-gray';
		let colorscheme  = this.props.colorscheme ? this.props.colorscheme : 'grdnt-yellow';
		
        return <section id="pricing" className={SectionColorScheme+ ' sec-pad-lg'}>
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="pricing wrapper">
						<div className="section-text text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
							<p className="meta-head">{getData.sub_title}</p>
							<h3>{getData.title}</h3>
						</div>
						<div className="pricing-btn text-center wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
							<label className="toggler toggler--is-active" id="filt-monthly">Monthly</label>
							<div className={colorscheme+ ' toggle'}>
								<input type="checkbox" id="switcher" className="check" />
								<b className="b switch"></b>
							</div>
							<label className="toggler" id="filt-hourly">Yearly</label>
						</div>
						<div className="spce"></div>
						<div className={'pricing text-center wow animated fadeInUp'} data-wow-duration="1.4s" data-wow-delay=".2s">
							<div id="monthly" className="clearfix model">

								{
								getData.singleTableMonthly.map( ( element, i ) => {
									return(
										<div key={i} className="col-sm-4">
											<div className={tableColorScheme+' pricing-table res-margin-sm'}>
												<div className="inner">
													<div className="pricing-header">
														<div className="main-pricing">
															<h5>{element.title}</h5>
															<h3 className="fw-400"><span className="clip-txt">{element.currency + element.price}</span><span className="meta fw-300"> {element.duration}</span></h3>
														</div>
													</div>
													<div className="pricing-content">
														<div className="pricing-feature">
															<ul>
																{
																	element.features.map( ( element, i ) => {
																	return(
																	<li key={i}>{element}</li>
																	)
																	})
																}
																
															</ul>
														</div>
													</div>
													<div className="pricing-footer">
														<div className="pricing-footer grad-prple">
														<a className={colorscheme + ' btn fill'} href={element.url}><span className="clip-txt">{element.btnLabel}</span></a>
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})
								}

							</div>
							<div id="hourly" className="none clearfix model">

							{
								getData.singleTableYearly.map( ( element, i ) => {
									return(
										<div key={i} className="col-sm-4">
											<div className="pricing-table bg-gray res-margin-sm">
												<div className="inner">
													<div className="pricing-header">
														<div className="main-pricing">
															<h5>{element.title}</h5>
															<h3 className="fw-400"><span className="clip-txt">{element.currency + element.price}</span><span className="meta fw-300">{element.duration}</span></h3>
														</div>
													</div>
													<div className="pricing-content">
														<div className="pricing-feature">
															<ul>
																{
																	element.features.map( ( element, i ) => {
																		return(
																		<li key={i}>{element}</li>
																		)
																	})
																}
															</ul>
														</div>
													</div>
													<div className="pricing-footer">
														<div className="pricing-footer grad-prple">
															<a className="btn grdnt-yellow fill" href={element.url}><span className="clip-txt">{element.btnLabel}</span></a>
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})
								}								

							</div>
						</div>		
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Pricing
