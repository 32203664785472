import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Clients extends Component {

    render () {
        let getData = homedata.clients
		let publicUrl = process.env.PUBLIC_URL+'/'
		let imgAlt = "client image"

        return (
			<section className="logo sec-pad pb-0 text-center">
				<div className="container">
					<div className="row">
						<div className="col-md-10 col-md-offset-1">
							{
								getData.map( ( element, i ) => {
									return(
										<div key={i} className="col-sm-3 col-xs-6 res-margin-sm">
											<img src={publicUrl+element} alt={imgAlt} />
										</div>
									)
								} )
							}
						</div>
					</div>
				</div>
			</section>
    	)
    }
}


export default Clients
