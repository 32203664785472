import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Blog extends Component {
    render () {

        let data = homedata.blogs
        let blogContent = data.blogcontent
		let publicUrl = process.env.PUBLIC_URL+'/'
		let bgColorScheme  = this.props.bgColorScheme ? this.props.bgColorScheme : '';
		let BorderColorScheme  = this.props.BorderColorScheme ? this.props.BorderColorScheme : 'grdnt-yellow';
		let colorscheme  = this.props.colorscheme ? this.props.colorscheme : 'grad-yellow';
		let imgAlt = "image"
		
        
        return <section className={bgColorScheme+' sec-pad-lg'}>
		<div className="container">
			<div className="row">
				<div className="section-text text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{data.sub_title}</p>
					<h3>{data.title}</h3>
				</div>
				<div className={colorscheme + 'post row wow animated fadeInUp'} data-wow-duration="1.2s" data-wow-delay=".2s">

					{ 
					blogContent.map( ( element, i ) => {
						return(
						<div key={i} className="col-md-4 res-margin">
							<div className="post-col">
								<a href={element.url}><img src={publicUrl+element.image} alt={imgAlt} /></a>
								<div className={colorscheme+ ' post-content'}>
									<div className="spce md"></div>
									<ul className="post-meta clearfix">
										<li>{element.postDate}</li>
										<li>
											<a href={element.url}><i className="fa fa-heart-o" aria-hidden="true"></i>{element.like}</a>
										</li>
										<li>
											<a href={element.url}><i className="fa fa-comment-o" aria-hidden="true"></i>{element.comments}</a>
										</li>
									</ul>
									<div className="post-text">
										<a href={element.url}> 
										<h5>{element.title}</h5>
										<p>{element.desc}</p>
										</a>
									</div>
									<a className={BorderColorScheme+ ' btn fill'} href={element.url}><span className="clip-txt">{element.readLink}</span></a>
								</div>
							</div>
						</div>
						)
					} )

					}
				</div>
			</div>
		</div>
	</section>
    }
}


export default Blog
