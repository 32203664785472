import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import homedata from '../../data/home.json';

class Video_V2 extends Component {
	
	constructor () {
		super()
		this.state = {
		  isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	 
	openModal () {
		this.setState({isOpen: true})
	}

    render () {
		
        let getData = homedata.videov2
        let publicUrl = process.env.PUBLIC_URL+'/'
		//
		const inlineStyle = {
            backgroundImage: 'url('+publicUrl+getData.background+')'
        }

        return (
		<div>
		<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={getData.video_id} onClose={() => this.setState({isOpen: false})} />
		<section className="sec-pad-sm light text-center">
			<div className="container">
				<div className="row">
					<div className="parallaxie sec-pad-lg" style={inlineStyle}>
						<h3>{getData.title}</h3>
						<div className="btn-holder text-center grad-prple">
						<span className="btn-round video" onClick={this.openModal}><i className="fa fa-play clip-txt" aria-hidden="true"></i></span>
						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
    )
    }
}


export default Video_V2
