import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Hero_V4 extends Component {

    render () {
        let getData = homedata.herov4
		let publicUrl = process.env.PUBLIC_URL+'/'
		let imgAlt = "image"

		const inlineStyle = {
            backgroundImage: 'url('+publicUrl+getData.bg_img+')'
        }


        return (
            <div id="home" className="hero style-curve grdnt-blue parallaxie overly" style={inlineStyle}>
			<div className="container">
				<div className="row hero-content">
					<div className="row">
						<div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
							<div className="intro-text light text-center">
								<h1>{getData.title}</h1>
								<div className="spce sm"></div>
								<p className="large">{getData.desc}</p>
								<div className="btn-holder">
								{
									getData.button.map( ( element, i ) => {
										return(
											<a key={i} href={element.btn_url}><img src={publicUrl+element.btn_img} alt={imgAlt} /></a>
										)
									})
								}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
			<div className="spce lg"></div>
			<div className="img-pre text-center">
				<img src={publicUrl+getData.mockup_img} alt={imgAlt}/>
			</div>
		</div>
    )
    }
}


export default Hero_V4


