import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Faq_V2 extends Component {
    render () {

        let getData = homedata.faqv2

        return <section className="sec-pad pt-0 grad-blue">
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="text col-md-5 res-margin wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
						<div className="head-bdr">
							<div className="text">
								<h4>{getData.title}</h4>
								<p className="large">{getData.desc}</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-md-offset-1 faq wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
						<div className="panel-group res-margin" id="accordion" role="tablist" aria-multiselectable="true">

									{
										getData.accordionContent.map( ( element, i ) => {
							
											let collapseIn = '';

											if( i === 0 ) {
												collapseIn = ' in';
											}
							
											return(
											<div key={i} className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingOne">
													<h4 className="panel-title">
														<a role="button" data-toggle="collapse" data-parent="#accordion" href={'#collapse'+i} aria-expanded="true" aria-controls="collapseOne">
															<i className="more-less glyphicon glyphicon-minus"></i>
															{element.title}
														</a>
													</h4>
												</div>
												<div id={'collapse'+i} className={`panel-collapse collapse${collapseIn}`} role="tabpanel" aria-labelledby="headingOne">
													<div className="panel-body">
														<p>{element.content}</p>
													</div>
												</div>
											</div>
											)
										})
									}

						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Faq_V2
