import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import homedata from '../../data/home.json';

class About extends Component {

	constructor () {
		super()
		this.state = {
		  isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	 
	openModal () {
		this.setState({isOpen: true})
	}


    render() {

		let aboutContent = homedata.about
		let title = aboutContent.title
		let desc = aboutContent.desc
		let publicUrl = process.env.PUBLIC_URL+'/'
		let image = publicUrl+aboutContent.image
		let imavideo_id = aboutContent.video_id
		let features = aboutContent.aboutFeatures
		let imgAlt = "image"
       
        return <div>
		<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={imavideo_id} onClose={() => this.setState({isOpen: false})} />
		<section className="sec-pad-lg grdnt-yellow light">
		<div className="container">
			<div className="row">
				<div className="flx-container res-center-sm align-flx-center flx-off-sm row">
					<div className="col-sm-4 res-margin-sm wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
						<div className="text">
							<h3>{title}</h3>
							<div className="spce"></div>
							<p>{desc}</p>
							<div className="spce"></div>
						</div>
					</div>
					<div className="col-sm-4 col-md-3 text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
						<img src={image} alt={imgAlt} />
						<div className="text-center grad-yellow btn-over">
						<span className="btn-round video" onClick={this.openModal}><i className="fa fa-play clip-txt" aria-hidden="true"></i></span>
						</div>
					</div>
					<div className="col-md-4 col-md-offset-1 col-sm-4">
						<div className="right-dir-col wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
						{
						features.map( ( element, i ) => {
							return(
							<div key={i} className="list">
								<div className="icon-md">
									<span className={element.icon} aria-hidden="true"></span>
								</div>
								<div className="text">
									<h5>{element.title}</h5>
									<p>{element.desc}</p>
								</div>
								<div className="spce"></div>
							</div>
							
							)

						} )	
						
						}		

						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	</div>
    }
}


export default About
