import React from 'react';
import Footer from './global-components/footer';
import HeroV2 from './home-components/hero-v2';
import Counter from './home-components/counter';
import FeatureV2 from './home-components/feature-v2';
import CTA from './home-components/cta';
import About from './home-components/about';
import Overview from './home-components/overview';
import OverviewV2 from './home-components/overview-v2';
import Screenshot from './home-components/screenshots';
import Pricing from './home-components/pricing';
import Testimonial from './home-components/testimonial';
import AppDownload from './home-components/app-download';
import Blog from './home-components/blog';
import Faq from './home-components/faq';
import Contact from './home-components/contact';
import { recordShare } from './api/api'

class Home extends React.Component {
    componentDidMount = async () => {
        console.log("new page");
        const a = window.location.href;
        var userid = 0;
        var property = "";

        if (a.includes("?")) {//判断是否有后缀属性，有的话说明是分享内容
            const u = a.split('?')
            const mid = u[u.length - 1]//分解出？后的内容
            console.log(mid)
            const p = mid.split('&')//将每个&存到数组
            console.log(p)
            for (var i = 0; i < p.length; i++) {
                if (p[i].includes("userid")) {//判断是否有userid
                    const userids = p[i].split('=')
                    userid = userids[userids.length - 1]
                    console.log(userid)
                } else {
                    property = property + p[i] + ";";
                    console.log(property);
                }

            }
            
            var w = await recordShare(userid, property, 1, 0, "download", "");

        }





    }




    render() {
        return <div>
            <HeroV2 />
            {/* <Counter />
            <FeatureV2 />
            <CTA />
            <About />
            <Overview />
            <OverviewV2 />
            <Screenshot />
            <Pricing />
            <Testimonial />
            <AppDownload />
            <Blog />
            <Faq />
            <Contact /> */}
            <Footer />
        </div>
    }

}

export default Home
