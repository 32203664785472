import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Subscribe extends Component {
    render() {

        let getData = homedata.subscription

        return <div className="subscribe-box flx-container align-flx-center flx-off-sm">
        <div className="col-md-6 res-margin">
        <h4 className="heading">{getData.title}</h4>
        </div>
        <div className="col-md-6">
            <div className="sub-input subscribe custom-form animated wow fadeInUp" data-wow-delay=".1s">
                <form className="mailchimp custom-form">
                    <div className="form-group">
                        <input type="email" name="subscribe" id="subscriber-email" placeholder="Enter your Email" className="form-control"/>
                    </div>
                    <button type="submit" id="subscribe-button" className="btn grdnt-purple">{getData.btnText}</button>
                </form>
                <div className="text-center light result-box">
                    <h5 className="subscription-success"> </h5>
                    <h5 className="subscription-error"> </h5>
                    <label className="subscription-label" htmlFor="subscriber-email"></label>
                </div>
            </div>
        </div>
    </div>
    }
}


export default Subscribe
