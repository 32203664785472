import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Overview_V2 extends Component {
    render () {

		let getData = homedata.overviewv2
		
        return <section className="sec-pad-lg pt-0">
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="flx-container res-center-sm align-flx-center flx-off-xs row">
						<div className="col-sm-5 col-md-5 res-margin-sm">
							<div className="overview-img wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
								<img src={getData.img} alt={getData.img_alt} />
							</div>
						</div>
						<div className="col-sm-6 col-md-5 col-md-offset-2">
							<div className="overview-text wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
								<div className="text grad-blue">
									<h3>{getData.title}</h3>
									<p>{getData.desc}</p>
								</div>
								<div className="btn-holder grad-yellow">
								{
									getData.buttons.map( ( element, i ) => {
										return(
										<a key={i} className={element.extraclass.anchorClass+' btn grdnt-yellow'} href={element.btnUrl}>
										<i className={element.btnicon+' '+element.extraclass.iconClass} aria-hidden="true"></i><span className={element.extraclass.textClass}>{element.btnLabel}</span></a>
										)
									})
								}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Overview_V2
