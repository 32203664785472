import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Overview_V3 extends Component {
    render () {

		let getData = homedata.overviewv3
		let publicUrl = process.env.PUBLIC_URL+'/'

        return <section id="overview" className="sec-pad-lg">
		<div className="container">
			<div className="row">
				<div className="col-md-10 col-md-offset-1">
					<div className="flx-container res-center-sm align-flx-center flx-off-sm row">
						<div className="col-md-5 col-sm-6 res-margin-sm">
							<div className="text">
								<h3>{getData.title}</h3>
								<div className="spce"></div>
								<p>{getData.desc}</p>
								<div className="spce"></div>
								<div className="right-dir-col grad-blue">

									{
										getData.items.map( ( element, i ) => {
											return(
											<div key={i} className="list">
												<div className="wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
													<div className="icon-sm clip-txt">
														<span className={element.icon} aria-hidden="true"></span>
													</div>
													<div className="text">
													<p className="large"><strong>{element.title}</strong> {element.desc}</p>
													</div>
												</div>
												<div className="spce"></div>
											</div>
											)
										})
									}
									
								</div>
							</div>
						</div>
						<div className="col-md-6 col-md-offset-1 col-sm-6 animated wow animated fadeInUp" data-wow-duration="2s">
							<img src={publicUrl+getData.img}/>
						</div>
					</div>	
				</div>
			</div>
		</div>
	</section>
    }
}


export default Overview_V3
