import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Overview_V4 extends Component {
    render () {

        let getData = homedata.overviewv4
		let publicUrl = process.env.PUBLIC_URL+'/'
		let imgAlt = "image";


        return <section className="sec-pad-lg pt-0">
		<div className="container">
			<div className="row">
				<div className="col-md-10 col-md-offset-1">
					<div className="flx-container res-center-sm align-flx-center flx-off-xs row">
						<div className="col-sm-5 res-margin-sm">
							<div className="overview-img wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
								<img src={publicUrl+getData.img} alt={imgAlt} />
							</div>
						</div>
						<div className="col-sm-6 col-md-5 col-md-offset-1">
							<div className="overview-text wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
								<div className="head-bdr">
									<div className="text grad-blue">
										<h3>{getData.title}</h3>
										<p>{getData.desc}</p>
										<div className="spce"></div>
										<div className="row">

										{
											getData.items.map( ( element, i ) => {
												return(
													<div key={i} className="col-sm-4">
														<div className="item text-center">
															<div className="icon-sm">
																<span className={element.icon + ' clip-txt'}></span>
															</div>
															<h6>{element.title}</h6>
														</div>
													</div>
												)
											})

										}											

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Overview_V4
