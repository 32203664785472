import React, { Component } from 'react';

class Comments extends Component {
    render () {

        let replayLink = '#';
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
            <div className="form-area custom-form">
                <h5>Post Comment</h5>
                <div className="spce"></div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <input name="name" className="form-control" placeholder="* Name" type="text" />
                    </div>
                    <div className="form-group col-md-6">
                        <input name="email" className="form-control" placeholder="* Email" type="email" />
                    </div>
                    <div className="form-group col-md-12">
                        <textarea name="message" className="form-control" placeholder="* Comment"></textarea>
                    </div>
                    <div className="col-md-12">
                        <button className="btn grdnt-yellow">Post comment</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default Comments
