import React from 'react';
import Footer from './global-components/footer';
import HeroV4 from './home-components/hero-v4';
import Clients from './home-components/clients';
import FeatureV2 from './home-components/feature-v2';
import VideoV2 from './home-components/video-v2';
import CounterV2 from './home-components/counter-v2';
import OverviewV3 from './home-components/overview-v3';
import OverviewV4 from './home-components/overview-v4';
import Pricing from './home-components/pricing';
import TestimonialV2 from './home-components/testimonial-v2';
import AppDownload from './home-components/app-download';
import Team from './home-components/team';
import Blog from './home-components/blog';
import FaqV2 from './home-components/faq-v2';
import Contact from './home-components/contact';

const Home = () => {
    return <div>
        <HeroV4 />
        <Clients />
        <FeatureV2 colorscheme="grdnt-blue" />
        <VideoV2 />
        <OverviewV3 />
        <OverviewV4 />
        <CounterV2 />
        <Pricing SectionColorScheme="grad-blue" tableColorScheme="grdnt-blue" colorscheme="grdnt-blue" />
        <FaqV2 />        
        <TestimonialV2 />
        <Team />
        <Blog bgColorScheme="bg-gray" colorscheme="grad-blue" BorderColorScheme="grdnt-blue" />
        <AppDownload colorscheme="grdnt-blue" />
        <Contact />
        <Footer colorscheme="grdnt-blue" />
    </div>
}


export default Home
