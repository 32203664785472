import React, { Component } from 'react';
import homedata from '../../data/home.json';


class Hero_V2 extends Component {

    render() {
        let getData = homedata.herov2
        let publicUrl = process.env.PUBLIC_URL + '/'
        let imgAlt = "image";

        return (
            <div id="home" className="hero grdnt-yellow bg-trans-2 style-wave-2">
                <div className="container">
                    <div className="row hero-content">
                        <div className="row">
                            <div className="col-sm-6 col-md-5">
                                <div className="intro-text light">
                                    <h1>Thanks! We have received your payment!</h1>
                                    <h1>Please Wait!</h1>
                                    <div className="spce"></div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-7 img-pre jump-anim">
                                <img src={publicUrl + getData.mockup_img} alt={imgAlt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Hero_V2
