import React, { Component } from 'react';
import Widgetdata from '../../data/widgetdata'

class Sidebar extends Component {
    
    render () {

        let getWidgetData = Widgetdata
        let getSearchWidget = getWidgetData.searchWidget
        let getLatestPost = getWidgetData.latestPost
        let getTags = getWidgetData.tags


        return <div className="col-md-3 col-md-offset-1 col-sm-4">
        <div className="bg-light side-post">
            <div className="side-col search sub-input">
                <div className="custom-form">
                    <input  placeholder={getSearchWidget.title} className="form-control" type="text" />
                    <button type="submit" className="btn fill"><span className="fa fa-search" aria-hidden="true"></span></button>
                </div>
            </div>

            <div className="spce md"></div>

            <div className="side-col popular clearfix">
                <h5>{getLatestPost.widgetTitle}</h5>
                {
                    getLatestPost.widgetPosts.map( ( data, i  ) => {
                        return (
                        <div key={i} className="post-block">
                            <div className="spce sm"></div>
                            <a  href={data.url}>
                                <h6 className="head-sm">{data.title}</h6>
                            </a>
                            <ul className="post-meta clearfix">
                                <li>{data.date}</li>
                            </ul>
                        </div>
                        )
                    })     
                }
            </div>


            <div className="spce md"></div>
            <div className="side-col tags">
                <h5>{getTags.widgetTitle}</h5>
                <div className="spce sm"></div>
                {
                    getTags.widgetPosts.map( ( data, i  ) => {
                        return (
                            <a key={i}  href={data.url}>{data.title}</a>
                        )
                    })     
                }
                
            </div>


        </div>
    </div>
    }
}


export default Sidebar
