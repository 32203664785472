import React, { Component } from 'react';
import homedata from '../../data/home.json';
import parse from 'html-react-parser';

class Contact extends Component {
    render () {

        let getData = homedata.contact
       


        return <section className="sec-pad-lg grad-yellow">
		<div className="container">
			<div className="row">
				<div className="section-text text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{getData.sub_title}</p>
					<h3>{getData.title}</h3>
				</div>
				<div className="row">
					<div className="col-md-10 col-md-offset-1  wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
						<div className="row">
							<div className="col-md-12 col-sm-12 res-margin">
								<ul className="grad-blue right-dir-col">
                                    <li>
                                        <span className="icon-sm clip-txt">
                                            <i className="ti-map"></i>
                                        </span>
                                        <div className="text">
											<h6>{getData.address_info.title}</h6>
											{parse( getData.address_info.address)}
                                        	
                                        </div>
                                        <div className="spce"></div>
                                    </li>
                                    <li>
                                        <span className="icon-sm clip-txt">
                                            <i className="ti-mobile"></i>
                                        </span>
										<div className="text"><h6>{getData.phone.title}</h6> {parse( getData.phone.number )}</div>
                                        <div className="spce"></div>
                                    </li>
                                    <li>
                                        <span className="icon-sm clip-txt">
                                            <i className="ti-email"></i>
                                        </span>
										<div className="text"><h6>{getData.mail.title}</h6>{parse( getData.mail.mailId ) }</div>
                                    </li>
                                </ul>
								
							</div>
							{/* <div className="col-md-7 col-sm-7">
								<div className="bg-gray contact-form pd-30">
									<h5>{getData.form_title}</h5>
									<div className="spce"></div>
									<form id="contact-form" className="custom-form" method="post" action="#" role="form">
										<div className="messages"></div>
										<div className="controls">
											<div className="form-group">
												<input id="form_name" type="text" name="name" className="form-control" placeholder="Enter your name *" required="required" data-error="Name is required."/>
												<div className="help-block with-errors"></div>
											</div>
											<div className="form-group">
												<input id="form_email" type="email" name="email" className="form-control" placeholder="Enter your email *" required="required" data-error="Valid email is required."/>
												<div className="help-block with-errors"></div>
											</div>
											<div className="form-group">
												<textarea id="form_message" name="message" className="form-control" placeholder="Message*" rows="4" required="required" data-error="Please,leave us a message."></textarea>
												<div className="help-block with-errors"></div>
											</div>
											<input type="submit" className="btn grdnt-yellow" value="Send Message"/>
										</div>
									</form>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    }
}


export default Contact
