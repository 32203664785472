import React, { Component } from 'react';
import CountUp  from 'react-countup';
import homedata from '../../data/home.json';

class Counter_V2 extends Component {
    render () {

		let getData = homedata.counterv2
		let publicUrl = process.env.PUBLIC_URL+'/'

        const inlineStyle = {
            backgroundImage: 'url('+publicUrl+getData.bg_img+')'
        }


        return <section className="grdnt-blue parallaxie light overly" style={inlineStyle}>
		<div className="container sec-pad-lg">
			<div className="static-warapper text-center col-md-10 col-md-offset-1 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
				
				{
					getData.items.map( ( element, i ) => {
						return(
						<div key={i} className="col-sm-4 counter-wrapper res-margin-sm">
							<div className="wow animated fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
								<div className="icon-bg-md grdnt-orange">
									<span className={element.icon} aria-hidden="true"></span>
								</div>
								<div className="spce"></div>
								<div className="text">
									<h4 className="counter mg-0"><CountUp end={element.number} duration={10} /></h4>
									<p>{element.title}</p>
								</div>
							</div>
						</div>
						)
					} )
				}
			</div>
		</div>
	</section>
    }
}


export default Counter_V2
