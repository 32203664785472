import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import homedata from '../../data/home.json';


class Video extends Component {

	constructor () {
		super()
		this.state = {
		  isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	 
	openModal () {
		this.setState({isOpen: true})
	}

    render () {
		let getData = homedata.video
		let publicUrl = process.env.PUBLIC_URL+'/'
		
		const inlineStyle = {
            backgroundImage: 'url('+publicUrl+getData.bg_image+')'
        }

        return (
			<div>
			<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={getData.video_id} onClose={() => this.setState({isOpen: false})} />
			<section className="grdnt-blue parallaxie overly" style={inlineStyle}>
			<div className="container sec-pad-lg">
				<div className="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
					<div className="text-center grad-blue">
						<span className="btn-round video" onClick={this.openModal}><i className="fa fa-play clip-txt" aria-hidden="true"></i></span>
					</div>
					<div className="spce"></div>
					<div className="text-center light">
						<h3>{getData.title}</h3>
						<p className="large">{getData.sub_title}</p>
					</div>
					<div className="col-md-8 col-md-offset-2 sec-pad-sm pb-0 light">
						<div className="row">

							{
								getData.features.map( ( element, i ) => {
									return(
										<div key={i} className="col-sm-3 col-xs-6 res-margin-sm">
											<div className="item text-center">
												<div className="icon-sm">
													<span className={element.icon}></span>
												</div>
												<h6>{element.title}</h6>
											</div>
										</div>
									)
								})
							}

						</div>
					</div>
				</div>
			</div>
		</section>
		</div>
    )
    }
}


export default Video
