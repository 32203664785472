import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import homedata from '../../data/home.json';

class Testimonial_V2 extends Component {
    render() {

	let getData = homedata.testimonialv2
    let publicUrl = process.env.PUBLIC_URL+'/'


    // owl Options
    const options = {
		loop:true,
		autoplayTimeout:3500,
		items:1,
		nav: false,
		navText: [],
		margin:20
	};


        return <section className="sec-pad-lg grdnt-blue">
		<div className="container">
			<div className="section-text text-center light">
				<div className="section-text text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
					<p className="meta-head">{getData.sub_title}</p>
					<h3>{getData.title}</h3>
				</div>
			</div>

			<OwlCarousel className="owl-carousel col-md-8 col-md-offset-2 quote wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s" options={options}>
			
			{
				getData.testimonial.map( ( element, i ) => {
					return(
					<div key={i} className="quote-item">
						<div className="quote-text bg-gray">
							<div className="rating">
								<ul className="rating-list">
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><p>({element.star})</p></li>
								</ul>
							</div>
							<p>{element.desc}</p>
							<div className="spce"></div>
							<div className="profile clearfix">
								<h6 className="heading">{element.name}</h6>
								<p>{element.position}</p>
							</div>
						</div>
						<div className="profile-img"><img src={publicUrl+element.img} alt={element.imgAlt}/></div>
					</div>
					)
				})
			}
										
			</OwlCarousel>
				
		</div>
	</section>
    }
}


export default Testimonial_V2
