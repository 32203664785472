import React, { Component } from "react";

import Footer from "../global-components/footer";
import OwlCarousel from "react-owl-carousel2";
import homedata from "../../data/home.json";

class Hero_V1 extends Component {
	render() {
		let getData = homedata.herov1;
		let publicUrl = process.env.PUBLIC_URL + "/";

		const options = {
			dots: false,
			loop: true,
			animateOut: "fadeOut",
			center: true,
			margin: 0,
			autoplay: true,
			autoplayTimeout: 2500,
			// autoWidth: true,
			touchDrag: false,
			items: 1,
			mouseDrag: false,
			nav: false,
			navText: [],
		};

		return (
			<div
				id='home'
				className='hero style-flat'
				style={{ paddingBottom: "0px" }}
			>
				<section className='sec-pad-lg pb-0'>
					<div className='container'>
						<div
							className='row hero-content'
							// style={{ paddingBottom: "100px" }}
						>
							<div className='row'>
								<div className='col-sm-6'>
									<div className='intro-text light'>
										<h1>{getData.title}</h1>
										<div className='spce'></div>
										<p className='large'>{getData.desc}</p>
										<div className='btn-holder'>
											{getData.button.map(
												(element, i) => {
													return (
														<a
															key={i}
															href={
																i === 2
																	? require("../../data/app-release.apk")
																	: element.btn_url
															}
														>
															<img
																src={
																	publicUrl +
																	element.btn_img
																}
																style={{
																	marginBottom:
																		"10px",
																}}
															/>
														</a>
													);
												}
											)}
											{/* <a
												// className='resume-button'
												href={require("../../data/app-release.apk")}
												target='_blank'
												rel='noopener noreferrer'
											>
												<img
													src={
														publicUrl +
														element.btn_img
													}
													style={{
														marginBottom: "10px",
													}}
												/>
											</a> */}
										</div>
									</div>
								</div>
								<div
									className=' col-sm-6 img-pre '
									// style={{ backgroundColor: "#A9D3EB" }}
								>
									<div
										className='slide-side text-center'
										// style={{ marginBottom: "30px" }}
									>
										<img
											className='phone'
											src={publicUrl + getData.mockup_img}
										/>
										<OwlCarousel
											className='owl-carousel nplr app-slide-auto '
											options={options}
										>
											{getData.screenshots.map(
												(element, i) => {
													return (
														<div key={i}>
															<img
																alt={
																	element.alt
																}
																src={
																	publicUrl +
																	element.img
																}
															/>
														</div>
													);
												}
											)}
										</OwlCarousel>
									</div>
									<div className='circle-bg grdnt-yellow'></div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <Footer /> */}
			</div>
		);
	}
}

export default Hero_V1;
