import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Overview extends Component {
    render () {

        let getData = homedata.overview

        return <section id="overview" className="sec-pad-lg">
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="flx-container res-center-sm align-flx-center flx-off-sm row">
						<div className="col-md-4 col-sm-6 res-margin-sm animated wow fadeInUp" data-wow-duration="2s">
							<div className="text">
								<h3>{getData.title}</h3>
								<div className="spce"></div>
								<p>{getData.desc}</p>
							</div>
							<div className="btn-holder grad-yellow">
								{
									getData.buttons.map( ( element, i ) => {
										return(
										<a key={i} className={element.extraclass.anchorClass+' btn grdnt-yellow'} href={element.btnUrl}>
											<i className={element.btnicon+' '+element.extraclass.iconClass} aria-hidden="true"></i><span className={element.extraclass.textClass}>{element.btnLabel}</span></a>
										)
									})
								}
								
							</div>
						</div>
						<div className="col-md-7 col-md-offset-1 col-sm-6 animated wow fadeInUp" data-wow-duration="4s">
							<img src={getData.img} />
						</div>
					</div>	
				</div>
			</div>
		</div>
	</section>
    }
}


export default Overview
