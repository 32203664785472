import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import homedata from '../../data/home.json';



class Screenshot extends Component {

    render() {
        
        let getData = homedata.screenshots
		let publicUrl = process.env.PUBLIC_URL+'/'
		let colorscheme  = this.props.colorscheme ? this.props.colorscheme : 'grdnt-yellow';

		const options = {
			loop:true,
			nav: false,
			navText: [],
			center:true,
			autoplay:true,
			autoplayTimeout:2000,
			margin:25,
			responsive:{
				320:{
					items:2,
					margin:10
				},
				481:{
					items:3,
					margin:60
				},
				991:{
					items:6
				}
			}
        };
        
        return <section className={colorscheme+' sec-pad-lg light'}>
		<div className="container">
			<div className="section-text text-center wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
				<p className="meta-head">{getData.sub_title}</p>
				<h3>{getData.title}</h3>
			</div>
		</div>
		<div className="screenshot wow animated fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
			<OwlCarousel  className="owl-carousel screen nplr screen-loop" options={options}>
			{
				getData.screenshot.map( ( element, i ) => {
				return(
					<div key={i}>
						<img alt={element.title} src={publicUrl+element.img}/>
					</div>
				)
				})

			}
			</OwlCarousel>
		</div>
	</section>
    }
}


export default Screenshot